import * as React from "react";
import Icon, {IconType} from "./Icon";
import {boundMethod} from "autobind-decorator";

/**
 *
 */
export enum IconsAnimated {
    MENU_BURGER = "jdes-animated-icon-menu-burger",
    CHECKBOX = "jdes-animated-icon-checkbox"
}

/**
 *
 */
export interface IconAnimatedProps {
    iconType:IconsAnimated,
    onClick?:Function,
    active?:boolean,
}

/**
 *
 */
class IconAnimated extends React.Component<IconAnimatedProps, any> {

    state:IconAnimatedProps = {
        iconType: IconsAnimated.MENU_BURGER,
        active: false
    }

    @boundMethod
    onClickHandler(event:any) {
        event.preventDefault();
        const {onClick, active} = this.props;
        let isClicked = active;

        isClicked = !isClicked;
        this.setState({
            active: isClicked,
        })
        onClick && onClick(event);

    }

    render() {
        const {iconType, active} = this.props;
        let classes = 'jdes-animated-icons ' + iconType + (active ? ' active' : '');
        switch (iconType)
        {
            case IconsAnimated.MENU_BURGER:
                return(
                    <span className={classes} onClick={this.onClickHandler}>&nbsp;
                        <Icon iconType={IconType.MENU_BURGER_TOP} />
                        <Icon iconType={IconType.MENU_BURGER_CENTER} additonalClass={"menu_burger_center_01"} />
                        <Icon iconType={IconType.MENU_BURGER_CENTER} additonalClass={"menu_burger_center_02"} />
                        <Icon iconType={IconType.MENU_BURGER_BOTTOM} />
                    </span>
                );
            case IconsAnimated.CHECKBOX:
                return(
                    <span className={classes}>&nbsp;
                        <Icon iconType={IconType.CHECKBOX_FRAME} />
                        <Icon iconType={IconType.CHECKBOX_HOOK} />
                    </span>
                );

        }
        return(
            <span>UndefinedIcon</span>
        );
    }
}

export default IconAnimated;