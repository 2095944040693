import SystemState, { initialSystemState } from "./SystemState";
import { AnyAction } from "redux";
import { SystemStateStorageKeys } from "./SystemStorageKeys";
import * as Actions from "./SystemStateActionTypes";

const SystemStateReducer = (
  state = initialSystemState,
  action: AnyAction
): SystemState => {
  const saved_state = localStorage.getItem(SystemStateStorageKeys.SYSTEM_STATE);
  if (saved_state) {
    state = { ...state, ...JSON.parse(saved_state) };
  }
  let updatedState: SystemState = {
    ...state,
  };

  switch (action.type) {
    case Actions.SHOW_USER_MENU:
      const userMenuActive = action.payload.userMenuActive;
      updatedState = {
        ...state,
        userMenuActive: userMenuActive,
      };
      localStorage.setItem(
        SystemStateStorageKeys.SYSTEM_STATE,
        JSON.stringify(updatedState)
      );
      return updatedState;

    case Actions.DIALOGUE_OPEN:
      const dialogOpen = action.payload.dialogOpen;
      updatedState = {
        ...state,
        dialogOpen: dialogOpen,
      };
      localStorage.setItem(
        SystemStateStorageKeys.SYSTEM_STATE,
        JSON.stringify(updatedState)
      );
      return updatedState;

    case Actions.CLEAR_STATE:
      localStorage.setItem(
        SystemStateStorageKeys.SYSTEM_STATE,
        JSON.stringify(initialSystemState)
      );
      return initialSystemState;

    default:
      localStorage.setItem(
        SystemStateStorageKeys.SYSTEM_STATE,
        JSON.stringify(updatedState)
      );
      return updatedState;
  }
};

export default SystemStateReducer;
