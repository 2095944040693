import * as React from "react";
import "./assets/style_main_wrapper.scss";

export interface MainWrapperProps {
    className?: string
}

class MainWrapper extends React.Component<MainWrapperProps, any> {

    render() {
        const {children, className} = this.props;
        const classes = className ? className : '';
        return (
            <main className={className}>
                {children}
            </main>
        );
    }
}

export default MainWrapper;