import * as React from "react";
import MainWrapper from "../main_structure/MainWrapper";
import BlockUI from "../blocks/BlockUI";
import "./assets/style_pages.scss";
import Headline from "../headline/Headline";

class Conditions extends React.Component<any, any> {
  render() {
    return (
      <MainWrapper>
        <BlockUI fullwidth={false} contentBlock={true}>
          <Headline title={"AGBs"} />
          <div>
            <h2>§ 1 Geltungsbereich, Vertragsschluss</h2>

            <p>
              <strong>1.</strong> Über das refuge.WoRoom Portal können die
              registrierten Nutzer Hilfsangebote, Hilfsgesuche und freiwillig
              angebotene Unterkünfte für Flüchtlinge über das Internet verwalten
              werden. Anbieter des Online Portals ist die Fronetic GmbH.
            </p>
            <p>
              <strong>2.</strong> Wenn der Nutzer vermutet, dass unberechtigte
              Dritte von den Daten Kenntnis erlangt haben oder ein
              Missbrauchsfall vorliegt, hat er die SW SG unverzüglich
              telefonisch und schriftlich - auch per Fax oder E-Mail – über
              nachfolgende Kontaktdaten zu informieren:
              <br />
              <br />
              <br />
              FRONETIC. GmbH
              <br />
              WoRoom Kundenservice
              <br />
              Alsenberger Straße 27
              <br />
              95028 Hof
              <br />
              Telefon: +49(0)9281 8397910
              <br />
              E-Mail: Kontakt@fronetic.de
            </p>
            <p>
              <strong>3.</strong> Allgemeine Geschäftsbedingungen des Kunden
              werden nicht Vertragsinhalt. Jeglichen Vertragsangeboten des
              Nutzers unter Hinweis auf seine Geschäftsbedingungen wird hiermit
              widersprochen.
            </p>
            <br />
            <br />
            <h2>§ 2 Ende der Nutzungsbedingungen</h2>

            <p>
              <strong>1.</strong> Bei Verdacht auf Missbrauch, auf Nutzerwunsch
              oder aus Gründen der Sicherheit kann die FRONETIC. GmbH den Zugang
              zum Online Portal sperren. Der Nutzer wird darüber nicht
              informiert.
            </p>
            <br />

            <h2>§ 3 Pflichten des Nutzers</h2>

            <p>
              <strong>1.</strong> Der Nutzer hat für seine Nutzungsberechtigung
              seine personenbezogene, namentliche E-Mail-Adresse anzugeben.
            </p>
            <p>
              <strong>2.</strong> Der Nutzer ist verpflichtet, seine
              Zugangsdaten (Benutzer und Passwort) geschützt vor dem Zugriff
              durch unberechtigte Dritte aufzubewahren.
            </p>
            <p>
              <strong>3.</strong> Der Nutzer hat alle von ihm eingegebenen Daten
              auf Vollständigkeit und Richtigkeit zu überprüfen.
            </p>
            <p>
              <strong>4.</strong> Der Nutzer hat sicherzustellen, dass die
              Einwilligung zur Verarbeitung personenbezogener Daten der Personen
              vorliegt, deren Daten verwaltet werden.
            </p>
            <br />

            <h2>§ 4 Haftung</h2>

            <p>
              <strong>1.</strong> Schadens- und Aufwendungsersatz-Ansprüche des
              Kunden (im Folgenden: Schadensersatzansprüche), gleich aus welchem
              Rechtsgrund, insbesondere wegen Verletzung von Pflichten aus dem
              Schuldverhältnis und aus unerlaubter Handlung, sind
              ausgeschlossen.
            </p>
            <p>
              <strong>2.</strong> Dies gilt nicht, soweit zwingend gehaftet
              wird, z.B. nach dem Produkthaftungsgesetz, in Fällen des Vorsatzes
              bzw. der groben Fahrlässigkeit, bei Fehlen einer garantierten
              Beschaffenheit, wegen der Verletzung des Lebens, des Körpers, der
              Gesundheit oder wegen der Verletzung wesentlicher
              Vertragspflichten. Der Schadensersatzanspruch für die Verletzung
              wesentlicher Vertragspflichten ist jedoch auf den
              vertragstypischen, vorhersehbaren Schaden begrenzt, soweit nicht
              Vorsatz oder grobe Fahrlässigkeit vorliegt oder wegen der
              Verletzung des Lebens, des Körpers oder der Gesundheit gehaftet
              wird. Eine Änderung der Beweislast zum Nachteil des Kunden ist mit
              den vorstehenden Regelungen nicht verbunden.
            </p>
            <p>
              <strong>3.</strong> Der Kunde ist vor Installation von Software
              zur umfangreichen Datensicherung verpflichtet.
              Schadensersatzansprüche für den Verlust gespeicherter Daten sind
              ausgeschlossen, wenn der Schaden bei ordnungsgemäßer
              Datensicherung nicht eingetreten wäre. Bei schuldhaften Handlungen
              von Fronetic die zum Datenverlust führt, wird die Haftung nach §
              254 BGB begrenzt.
            </p>
            <p>
              <strong>4.</strong> Der Kunde ist verpflichtet, etwaige Schäden im
              Sinne der vorstehenden Haftungsregelung unverzüglich gegenüber
              Fronetic schriftlich anzuzeigen oder von Fronetic aufnehmen zu
              lassen, so dass Fronetic möglichst frühzeitig informiert ist und
              eventuell gemeinsam mit dem Kunden noch Schadensminderung
              betreiben kann.
            </p>
            <br />
            <h2>§ 5 Geheimhaltung</h2>

            <p>
              Die Vertragsparteien verpflichten sich, sämtliche ihnen im
              Zusammenhang mit der Geschäftsverbindung zugänglich werdenden
              Informationen, die als vertraulich bezeichnet werden oder aufgrund
              sonstiger Umstände als Geschäfts- oder Betriebsgeheimnisse
              erkennbar sind, unbefristet geheim zu halten und sie, soweit nicht
              zur Erreichung des jeweiligen Vertragszwecks geboten, weder
              aufzuzeichnen noch an Dritte weiterzugeben oder in irgendeiner
              Weise zu verwerten.
            </p>
            <br />
            <h2>§ 6 Datensicherheit</h2>

            <p>
              Dem Nutzer obliegt es die Zugangsdaten zum Online Portals
              (Benutzername und Passwort) geheim zu halten. Sofern Dritte
              darüber Kenntnis erlangen
            </p>
            <p>
              Die Datenübertragung erfolgt über eine sichere
              SSL-Verschlüsselung, ein entsprechender Browser ist daher vom
              Nutzer zu verwenden.
            </p>
            <br />
            <h2>§ 6 Datenschutz</h2>

            <p>
              Informationen zum Datenschutz finden Sie unter
              https://www.fronetic.com/datenschutzerklaerung/
            </p>
            <p>
              Die Datenübertragung erfolgt über eine sichere
              SSL-Verschlüsselung, ein entsprechender Browser ist daher vom
              Nutzer zu verwenden.
            </p>
            <br />
            <h2>§ 7 Schlussbestimmungen</h2>

            <p>
              <strong>1.</strong> Sollten einzelne Bestimmungen dieser
              Allgemeinen Vertragsbedingungen unwirksam sein oder werden, so
              wird die Wirksamkeit der übrigen Bestimmungen hierdurch nicht
              berührt. Die Parteien verpflichten sich, an der Vereinbarung eine
              Regelung mitzuwirken, die den wirtschaftlichen Zweck der
              unwirksamen Regelung möglichst weitgehend zur Geltung bringt und
              diese ersetzt.
            </p>
            <p>
              <strong>2.</strong> Es gilt ausschließlich deutsches Recht.
              UN-Kaufrecht wird ausgeschlossen.
            </p>
            <p>
              <strong>3.</strong> Ausschließlicher Gerichtsstand ist Hof.
              Fronetic ist jedoch auch berechtigt, am Hauptsitz des Kunden Klage
              zu erheben.
            </p>
          </div>
        </BlockUI>
      </MainWrapper>
    );
  }
}

export default Conditions;
