import * as React from "react";
import {MouseEventHandler} from "react";
export enum IconType {
    STAR = "jdes-icons-star",
    GLOBE = "jdes-icons-globe",
    THEROOM = "jdes-icons-theroom",
    MENU_BURGER = "jdes-icons-menu-burger",
    SEARCH = "jdes-icons-search",
    LOGIN = "jdes-icons-login",
    MENU_BURGER_TOP = "jdes-icons-menu-burger-top",
    MENU_BURGER_CENTER = "jdes-icons-menu-burger-center",
    MENU_BURGER_BOTTOM = "jdes-icons-menu-burger-bottom",
    HOOK = "jdes-icons-hook",
    CHEVRON_LEFT = "jdes-icons-chevron-left",
    CHEVRON_RIGHT = "jdes-icons-chevron-right",
    CHECKBOX_FRAME = "jdes-icons-checkbox-frame",
    CHECKBOX_HOOK = "jdes-icons-checkbox-hook",
    CLOSE = "jdes-icons-close",
    MAIL = "jdes-icons-mail",
    ROOMS_GENERAL = "jdes-icons-rooms-general",
    ROOMS_OFFICES = "jdes-icons-rooms-offices",
    ROOMS_WORKSHOP = "jdes-icons-rooms-workshop",
    ROOMS_STORAGE = "jdes-icons-rooms-storage",
    FILTER = "jdes-icons-filter",
    ADD = "jdes-icons-add-circle",
    CIRCLE_EMPTY = "jdes-icons-notification-circle-empty",
    CIRCLE_FILLED = "jdes-icons-notification-circle",
    MORE = "jdes-icons-more-vert",
}

export interface IconProps {
    iconType:IconType,
    additonalClass?: string,
    onClick?:MouseEventHandler
}

class Icon extends React.Component<IconProps, any> {

    render() {
        const {iconType, additonalClass, onClick} = this.props;
        let classes = 'jdes-icons ' + iconType + (additonalClass ? ' ' + additonalClass : '');
        return(
            <span className={classes} onClick={onClick} />
        );
    }
}

export default Icon;