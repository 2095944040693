import * as React from "react";
import MainWrapper from "../main_structure/MainWrapper";
import BlockUI from "../blocks/BlockUI";
import "./assets/style_pages.scss";
import Headline from "../headline/Headline";

class PrivacyPolicy extends React.Component<any, any> {
  render() {
    return (
      <MainWrapper>
        <BlockUI fullwidth={false} contentBlock={true}>
          <Headline title={"Datenschutzerklärung"} />
          <div>
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
            <br />
            <br />
            <h3>Datenerfassung auf unserer Website</h3>
            <strong>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </strong>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
            <strong>Wie erfassen wir Ihre Daten?</strong>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch beim
            Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
            allem technische Daten (z.B. Internetbrowser, Betriebssystem oder
            Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie unsere Website betreten.
            <strong>Wofür nutzen wir Ihre Daten?</strong>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
            <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Außerdem haben Sie das Recht, unter bestimmten Umständen die
            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung
            unter „Recht auf Einschränkung der Verarbeitung“.
            <br />
            <br />
            <h3>Analyse-Tools und Tools von Drittanbietern</h3>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
            sie durch die Nichtbenutzung bestimmter Tools verhindern.
            Detaillierte Informationen dazu finden Sie in der folgenden
            Datenschutzerklärung. Sie können dieser Analyse widersprechen. Über
            die Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
            <br />
            <br />
            <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
            <h3>Datenschutz</h3>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
            <br />
            <br />
            <h3>Hinweis zur verantwortlichen Stelle</h3>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br />
            <br />
            FRONETIC. GmbH
            <br />
            Alsenberger Straße 27
            <br />
            95028 Hof
            <br />
            <br />
            Telefon: +49 (0)9281 839 791 0<br />
            E-Mail: kontakt@fronetic.de
            <br />
            <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
            <br />
            <br />
            <h3>Gesetzlich vorgeschriebener Datenschutzbeauftragter</h3>
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten
            bestellt.
            <br />
            <b />
            ITs HEIN GmbH
            <br />
            Kulmbacher Straße 27b
            <br />
            95460 Bad Berneck
            <br />
            <br />
            Telefon: +49 (0)9273 5013910
            <br />
            E-Mail: info@its-datenschutz.de
            <br />
            Web: www.its-datenschutz.de
            <br />
            <br />
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
            E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
            <br />
            <br />
            <h3>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h3>
            <strong>
              Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e
              oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen,
              die sich aus Ihrer besonderen Situation ergeben, gegen die
              Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen;
              dies gilt auch für ein auf diese Bestimmungen gestütztes
              Profiling. Die jeweilige Rechtsgrundlage, auf denen eine
              Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung.
              Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen
              personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
              können zwingende schutzwürdige Gründe für die Verarbeitung
              nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen
              oder die Verarbeitung dient der Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1
              DSGVO).
            </strong>
            <strong>
              Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung
              zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen
              die Verarbeitung Sie betreffender personenbezogener Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten
              anschließend nicht mehr zum Zwecke der Direktwerbung verwendet
              (Widerspruch nach Art. 21 Abs. 2 DSGVO).
            </strong>
            <br />
            <br />
            <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe.
            <br />
            <br />
            <h3>Recht auf Datenübertragbarkeit</h3>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
            <br />
            <br />
            <h3>SSL- bzw. TLS-Verschlüsselung</h3>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://”
            auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
            können die Daten, die Sie an uns übermitteln, nicht von Dritten
            mitgelesen werden.
            <br />
            <br />
            <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            <br />
            <br />
            <h3>Recht auf Einschränkung der Verarbeitung</h3>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
            Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:
            <ul>
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah / geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
            </ul>
            <br />
            <br />
            <h2>3. Datenerfassung auf unserer Website</h2>
            <h3>Cookies</h3>
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
            Die meisten der von uns verwendeten Cookies sind so genannte
            “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so
            einstellen, dass Sie über das Setzen von Cookies informiert werden
            und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen sowie das automatische
            Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
            Deaktivierung von Cookies kann die Funktionalität dieser Website
            eingeschränkt sein. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies
            zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
            in dieser Datenschutzerklärung gesondert behandelt.
            <br />
            <br />
            <h3>Server-Log-Dateien</h3>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li>
              <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li>
              <li>IP-Adresse</li>
            </ul>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der technisch fehlerfreien Darstellung und
            der Optimierung seiner Website – hierzu müssen die Server-Log-Files
            erfasst werden.
            <br />
            <br />
            <h3>Kontaktformular</h3>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter. Die Verarbeitung der in das
            Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
            Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
            können diese Einwilligung jederzeit widerrufen. Dazu reicht eine
            formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis
            zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
            Widerruf unberührt. Die von Ihnen im Kontaktformular eingegebenen
            Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung
            Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
            <br />
            <br />
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
            Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei
            uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf
            Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
            beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
            DSGVO) und / oder auf unseren berechtigten Interessen (Art. 6 Abs. 1
            lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven
            Bearbeitung der an uns gerichteten Anfragen haben. Die von Ihnen an
            uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis
            Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
            widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
            nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
            gesetzliche Bestimmungen – insbesondere gesetzliche
            Aufbewahrungsfristen – bleiben unberührt.
            <br />
            <br />
            <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
            Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
            soweit sie für die Begründung, inhaltliche Ausgestaltung oder
            Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
            Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
            Verarbeitung von Daten zur Erfüllung eines Vertrags oder
            vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über
            die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
            Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder
            abzurechnen. Die erhobenen Kundendaten werden nach Abschluss des
            Auftrags oder Beendigung der Geschäftsbeziehung gelöscht.
            Gesetzliche Aufbewahrungsfristen bleiben unberührt.
            <br />
            <br />
            <h2>5. Eigene Dienste</h2>
            <h3>Bewerbungen</h3>
            Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
            per E-Mail, postalisch oder via Online-Bewerberformular). Im
            Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
            Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen
            Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung
            Ihrer Daten in Übereinstimmung mit geltendem Datenschutzrecht und
            allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten
            streng vertraulich behandelt werden.
            <br />
            <br />
            <strong>Umfang und Zweck der Datenerhebung </strong>
            <br />
            Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
            damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
            Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
            Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
            Begründung eines Beschäftigungsverhältnisses erforderlich ist.
            Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
            (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b
            DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
            Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
            Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen Daten
            werden innerhalb unseres Unternehmens ausschließlich an Personen
            weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt
            sind. Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
            eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6 Abs.
            1 lit. b DSGVO zum Zwecke der Durchführung des
            Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
            gespeichert.
            <br />
            <br />
            <strong>Aufbewahrungsdauer der Daten</strong>
            <br />
            Wenn wir Ihnen kein Stellenangebot machen können, Sie ein
            Stellenangebot ablehnen, Ihre Bewerbung zurückziehen, Ihre
            Einwilligung zur Datenverarbeitung widerrufen oder uns zur Löschung
            der Daten auffordern, werden die von Ihnen übermittelten Daten inkl.
            ggf. verbleibender physischer Bewerbungsunterlagen für maximal 6
            Monate nach Abschluss des Bewerbungsverfahrens gespeichert bzw.
            aufbewahrt (Aufbewahrungsfrist), um die Einzelheiten des
            Bewerbungsprozesses im Falle von Unstimmigkeiten nachvollziehen zu
            können (Art. 6 Abs. 1 lit. f DSGVO). DIESER SPEICHERUNG KÖNNEN SIE
            WIDERSPRECHEN, SOFERN IHRERSEITS BERECHTIGTE INTERESSEN VORLIEGEN,
            DIE UNSERE INTERESSEN ÜBERWIEGEN. Nach Ablauf der Aufbewahrungsfrist
            werden die Daten gelöscht, sofern keine gesetzliche
            Aufbewahrungspflicht oder ein sonstiger Rechtsgrund zur weiteren
            Speicherung vorliegt. Sofern ersichtlich ist, dass die Aufbewahrung
            Ihrer Daten nach Ablauf der Aufbewahrungsfrist erforderlich sein
            wird (z. B. aufgrund eines drohenden oder anhängigen Rechtsstreits),
            findet eine Löschung erst statt, wenn die Daten gegenstandslos
            geworden sind. Sonstige gesetzliche Aufbewahrungspflichten bleiben
            unberührt.
          </div>
        </BlockUI>
        <BlockUI fullwidth={false}></BlockUI>
      </MainWrapper>
    );
  }
}

export default PrivacyPolicy;
