import * as React from "react";
import MainWrapper from "../main_structure/MainWrapper";
import HeroUI from "../hero/HeroUI";
import InfoSection from "../info_sections/InfoSection";
import BlockUI from "../blocks/BlockUI";
import il_integration from "./assets/illustration_integration_new.png";
import il_payment from "./assets/illustration_payment.png";
import il_rmang from "./assets/illustration_roommanagement_2.png";
import il_verify_profile from "./assets/illustration_profile_verification.png";
import il_verify_room from "./assets/illustration_verified_room.png";
import il_rating from "./assets/illustration_rating.png";
import il_bill from "./assets/illustrations_bills.png";
import il_bookings from "./assets/illustration_booking.png";
import il_messenger from "./assets/illustration_messenge.png";

import icon_office from "./assets/woroom_main_icon_office.svg";
import icon_workshop from "./assets/woroom_main_icon_workshop.svg";
import icon_area from "./assets/woroom_main_icon_storage.svg";
import "./assets/style_startpage.scss";
import store from "../../store/store";
import {Locale} from "../../services/system/SystemState";
import {connect} from "react-redux";
import StepBox, {StepBoxStep} from "../step_box/StepBox";
import FlexElementCollection, {FlexElementCollectionStyle} from "../flex_element_collection/FlexElementCollection";
import Card, {CardAccentColors, CardStyle} from "../cards/Card";

export interface StartpageProps {
    token?: string;
    locale?: Locale;
}

export interface StartpageState {

}

class Startpage extends React.Component<StartpageProps, StartpageState> {
    state = {
        summary: {
            helpersTotal: 0,
            roomsTotal: 0,
            helpOffersTotal: 0,
            helpRequestsTotal: 0,
        },
    };

    async init() {

    }

    async componentDidMount() {
        await this.init();
    }

    render() {
        const {locale, token} = this.props;
        const {summary} = this.state;

        const getSummaryCard = (name: string, value: string | number) => {
            return (
                <div className={"startpage_summary_card"}>
                    <div className={"startpage_summary_card_core"}>
                        <div className={"startpage_summary_card_content"}>
                            <div className={"the_counter"}>{value ? value : 0}</div>
                            <div className={"the_title"}>{name}</div>
                        </div>
                    </div>
                </div>
            );
        };

        const howItWorks = () => {
            return (
                <StepBox>
                    <StepBoxStep number={1} title={"Erstellen Sie ein Profil"}
                                 content={"Erstellen Sie ein kostenloses Profil auf WoRoom und füllen Sie dieses mit aussagekräftigen Informationen. Damit schaffen Sie Vertrauen und erhöhen Ihre Sichtbarkeit."}/>
                    <StepBoxStep number={2} title={"Machen Sie Ihren Room schick"}
                                 content={"Nutzen Sie unsere Informationen und Checklisten, um Ihren Room in einen WoRoom umzuwandeln. Wir helfen Ihnen dabei, Ihren Room an die Anforderungen Ihrer zukünftigen Kunden anzupassen."}/>
                    <StepBoxStep number={3} title={"Legen Sie Ihren Room an"}
                                 content={"Ist Ihr Room vorbereitet, machen Sie ein paar Fotos, erstellen Sie eine Beschreibung und legen ein Preismodell fest. Sobald Sie ihren Room veröffentlichen, kann dieser von Besuchern der WoRoom Plattform gefunden und gebucht werden."}/>
                    <StepBoxStep number={4} title={"Vermieten Sie Ihren Room"}
                                 content={"Und freuen Sie sich über glückliche Kunden ;) "}/>
                </StepBox>
            );
        }

        const faq = () => {
            return (
                <StepBox>
                    <StepBoxStep number={1} title={"Was ist Co-Working"}
                                 content={"Co-Working ist die Abkürzung für kollaboratives Arbeiten. Es handelt sich dabei um eine neue Art des Arbeitens in einer innovativen Umgebung, in der Regel in einem gemeinsam genutzten Arbeitsraum, der flexibler ist als herkömmliche Büroumgebungen."}/>
                    <StepBoxStep number={2} title={"Co-Working schont Ressourcen"}
                                 content={"Co-Working bietet die gemeinsame Nutzung von Geräten, Ressourcen, Ideen und/oder Fachwissen."}/>
                    <StepBoxStep number={3} title={"Warum ist Co-Working so einzigartig"}
                                 content={"Arbeitnehmer können unabhängig und selbstbestimmt bleiben, haben aber oft auch mehr Möglichkeiten, Kontakte zu knüpfen und sich mit Gleichgesinnten zu vernetzen."}/>
                    <StepBoxStep number={4} title={"Coworking steigert die Produktivität"}
                                 content={"Da weniger Ablenkungen, eine kollektive Denkweise und gut ausgestattete Räumlichkeiten vor Ort zu einem kollaborativen Arbeitsumfeld beitragen, können Arbeitnehmer in einer Co-Working-Umgebung in der Regel besser arbeiten und haben mehr Freude an ihrer Arbeit als bei anderen Arbeitsformen."}/>

                </StepBox>
            );
        }


        return (
            <>
                <HeroUI contentHero={true}>
                    <div className={"startpage_hero_content"}>
                        <BlockUI fullwidth={false}>
                            <div className={"main_title"}>Mehr Miteinander</div>
                            <div className={"sub_title"}>Leerstände reduzieren, den ländlichen Raum beleben, mehr aus
                                Ihren Immobilien machen
                            </div>
                        </BlockUI>
                    </div>
                </HeroUI>
                <MainWrapper>
                    <BlockUI fullwidth={false}>
                        <FlexElementCollection style={FlexElementCollectionStyle.ROW_QUAD}>
                            <Card cardStyle={CardStyle.CTA} svgIcon={icon_office} title={"Büro"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"Entspannt arbeiten"}
                                  description={"Stellen Sie Ihre Büroflächen, Meetingräume als Co-Working-Spaces für Angestellte, Freelancer und Unternehmen bereit"}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} svgIcon={icon_workshop} title={"Werkstatt"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"Kreativ leben"}
                                  description={"Vermieten Sie Ihre Werkstatt mit und ohne Ausstattung, wenn Sie diese gerade nicht benötigen."}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} svgIcon={icon_area} title={"Flächen"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"Platz schaffen"}
                                  description={"Bieten Sie Lagerflächen, Fotostudios, Eventhallen oder Flächen aller Art an."}
                                  coverArt={""}/>
                        </FlexElementCollection>
                    </BlockUI>
                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2><strong>Co-Spacing</strong> - unsere Vision für eine effektivere, kollaborative Nutzung von
                            Büros, Werkstätten und Flächen aller Art.<br /><span className={"sideinfo"}>verfügbar ab Q3 2022</span> </h2>
                    </BlockUI>
                    <InfoSection
                        title={"Raumverwaltung"}
                        background={il_rmang}
                        text={<>WoRoom wird es Ihnen ermöglichen, Ihre Räumlichkeiten vom <strong>Schreibtischarbeitsplatz
                            bis hin zum Gebäudekomplex</strong> zu verwalten und Kapazitäten effizient zu
                            orchestrieren. WoRoom kann dabei auch als <strong>eigenständiges
                                Raumverwaltungssystem</strong> verwendet werden, ohne die Räumlichkeiten zur Vermietung
                            anzubieten.</>}
                    />
                    <InfoSection
                        title={"Integration"}
                        background={il_integration}
                        text={"WoRoom wird nach Bedarf in bestehende Systeme zur Raum- und Terminverwaltung eingebunden werden können. Dafür stellen wir sowohl eine eigene API als auch individuelle Schnittstellen zu üblichen Anwendungen wie z. B. MS Outlook zur Verfügung."}
                        reverse={true}
                    />


                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2>Zentrale Verwaltungsplattform</h2>
                    </BlockUI>

                    <InfoSection
                        title={"Buchungen"}
                        background={il_bookings}
                        text={"WoRoom wird ein umfangreiches Buchungssystem zur Verfügung stellen. Stunden-, tages-, wochenweise oder längerfristige Buchungen werden möglich sein."}
                    />
                    <InfoSection
                        title={"Rechnungen"}
                        background={il_bill}
                        text={"WoRoom wird automatisch Rechnungsunterlagen erzeugen und stellt diese als PDF zur Verfügung stellen."}
                        reverse={true}
                    />
                    <InfoSection
                        title={"Zahlungen"}
                        background={il_payment}
                        text={"Unkomplizierte, einfache und schnelle Abwicklung von Zahlungen direkt über WoRoom. Dafür werden Ihnen verschiedene integrierte Zahlungsdienstleister zur Verfügung stehen."}
                    />
                    <InfoSection
                        title={"Kommunikation"}
                        background={il_messenger}
                        text={"Ein integrierter Messenger wird es Ihnen und Ihren Kunden ermöglichen, direkt über WoRoom miteinander zu kommunizieren."}
                        reverse={true}
                    />


                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2>Machen Sie mehr aus Ihren Immobilien!</h2>
                    </BlockUI>


                    <BlockUI fullwidth={false}>
                        <br/><br/><br/>
                        <h2>So wirds funktionieren: Anbieter</h2>
                        <br/>
                        {howItWorks()}
                        <br/>
                    </BlockUI>
                    <BlockUI fullwidth={false}>
                        <h2>WoRoom wird Ihnen alle notwendigen Werkzeuge bereitstellen</h2>
                        <br/>
                        <FlexElementCollection style={FlexElementCollectionStyle.ROW_TRIPPLE}>
                            <Card cardStyle={CardStyle.CTA} title={"Statistische Auswertungen"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"alles im Blick"}
                                  description={"Wie oft wird Ihr Room gefunden, wie sehen Ihre Buchungsverläufe aus. "}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Push-Messages"} accentColor={CardAccentColors.BLUE}
                                  pretitle={"immer auf dem Laufenden"}
                                  description={"Optionale Benachrichtigungen bei Buchungen, Buchungsbestätigungen und Rückfragen."}
                                  price={0} coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Messenger"} accentColor={CardAccentColors.BLUE}
                                  pretitle={"Einfache Kommunikation"}
                                  description={"Direkte Kommunikation zwischen Anbietern und Kunden."} price={0}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Check-in-and-out"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"einfache Handhabung"}
                                  description={"Einfache Check-in und Check-out Funktionen über die Plattform."}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Belegungspläne"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"Übersicht ist alles"}
                                  description={"Raumbelegungspläne, die mittels API über andere Medien ausgespielt werden können."}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Datenschutz"} accentColor={CardAccentColors.YELLOW}
                                  pretitle={"Nur das Wichtigste"}
                                  description={"Hosting und Datenverarbeitung in Deutschland. "} price={0}
                                  coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Preismodelle"} accentColor={CardAccentColors.BLUE}
                                  pretitle={"Angebot und Nachfrage"}
                                  description={"Erstellen Sie saisonale Preismodelle, um eine möglichst gleichmäßige Auslastung Ihrer Rooms zu erhalten."}
                                  price={0} coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Abonnements"} accentColor={CardAccentColors.BLUE}
                                  pretitle={"Kontinuität und Planbarkeit"}
                                  description={"Bieten Sie Abonnements auf Ihre Rooms an. Schaffen Sie sich finanzielle Planungssicherheit."}
                                  price={0} coverArt={""}/>
                            <Card cardStyle={CardStyle.CTA} title={"Zahlungssysteme"}
                                  accentColor={CardAccentColors.ORANGE} pretitle={"reibungslos und unkompliziert"}
                                  description={"Überwachen Sie die Zahlungseingänge Ihrer Buchungen."} coverArt={""}/>
                        </FlexElementCollection>
                    </BlockUI>
                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2>
                            Vertrauen schaffen, <strong>Qualität sichern</strong>
                        </h2>
                    </BlockUI>
                    <InfoSection
                        title={"Profil-Verifikation"}
                        background={il_verify_profile}
                        text={"Nutzer von WoRoom werden die Möglichkeit erhalten, ihr Profil zu verifizieren. Die Verifikation wird durch einen Personalausweis oder bei Unternehmen durch Vorlage einer Gewerbeanmeldung durchgeführt. Verifizierte Profile werden dadurch nicht nur optisch aufgewertet. Anbieter erhalten dadurch eine bessere Position in Suchergebnissen."}
                    />
                    <InfoSection
                        title={"WoRoom-Zertifizierung"}
                        background={il_verify_room}
                        text={"Es wird ein Zertifizierungsverfahren für Räume und Gebäude geben. Die Zertifizierung bewirkt eine bessere Positionierung in Suchergebnissen und eine verbesserte Sichtbarkeit durch eine optisch aufgewertete Darstellung. Auch die Detailansicht des Rooms wird optisch und inhaltlich aufgewertet."}
                        reverse={true}
                    />
                    <InfoSection
                        title={"Bewertungssystem"}
                        background={il_rating}
                        text={"Anbieter und Kunden können sich gegenseitig bewerten. Dafür stellen wir ein mehrstufiges Bewertungssystem zur Verfügung. Die besten Bewertungen können zusätzlich als Testimonials auf Profilen angezeigt werden."}
                    />

                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2>Co-Working</h2>
                    </BlockUI>

                    <BlockUI fullwidth={false}>
                        <br /><br />
                        {faq()}
                    </BlockUI>

                    <BlockUI fullwidth={false} additionalClass={"cta"}>
                        <h2><strong>Co-Spacing</strong> - mehr als Co-Working</h2>
                    </BlockUI>
                    <BlockUI fullwidth={false}>
                        <br/><br/><br/>Co-Working zielt in aller Regel auf Büroarbeiten ab. Wir sind der Meinung, dass
                        dieses Konzept insbesondere <strong>mit einem regionalen Bezug auch für andere Tätigkeitsfelder
                        interessant ist.</strong>
                    </BlockUI>

                </MainWrapper>

            </>
        );
    }
}

const mapStateToProps = () => {
    const {locale, token} = store.getState().SystemStateReducer;
    return {
        locale: locale,
        token: token,
    };
};

export default connect(mapStateToProps, null)(Startpage);
