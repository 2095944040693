import * as React from "react";
import "./assets/style_flex_element_collection.scss";

export enum FlexElementCollectionStyle {
    ROW_DEFAULT = "row_default",
    ROW_SINGLE = "row_single",
    ROW_DUO = "row_duo",
    ROW_TRIPPLE = "row_tripple",
    ROW_QUAD = "row_quad",
    ROW_PENTA = "row_penta",
    ROW_DECA = "row_deca",
}

export interface FlexElementCollectionProps {
    style?:FlexElementCollectionStyle,
    additionalClass?:string,
    mobileHorizontalSlidebar?:boolean,
    mobileFull?:boolean,
    growItems?:boolean
}

class FlexElementCollection extends React.Component<FlexElementCollectionProps, any> {
    render() {
        const {children, style, additionalClass, mobileHorizontalSlidebar, mobileFull, growItems} = this.props;
        let classes = style ? "element_collection " + style : "element_collection";
        classes = additionalClass ? classes + " " + additionalClass : classes;
        classes = mobileHorizontalSlidebar ? classes + " hzm" : classes
        if(growItems) {
            classes += ' grow_items';
        }
        if(mobileFull)
        {
            classes += ' mobile_full';
        }
        return (
            <div className={classes}>
                {children}
            </div>
        );
    }
}

export default FlexElementCollection;