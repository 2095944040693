import * as React from "react";
import MainWrapper from "../main_structure/MainWrapper";
import BlockUI from "../blocks/BlockUI";
import {PrimaryRoutes} from "../../routes/PrimaryRoutes";
import "./assets/style_pages.scss";
import Headline from "../headline/Headline";

class Imprint extends React.Component<any, any> {
    render() {
        return (
            <MainWrapper>
                <BlockUI fullwidth={false} contentBlock={true}>
                    <Headline title={"Impressum"} />
                    <p>
                        Geschäftsführer: Sebastian Pieper (ViSdP), Bertram Schlecht, Marc Tigges<br />
                        Inhaltlich Verantwortlicher: Sebastian Pieper<br />
                        Umsatzsteuer-Identifikationsnummer: DE296904844
                    </p>
                    <p>
                        FRONETIC. GmbH<br />
                        Alsenberger Straße 27<br />
                        95028 Hof
                    </p>
                    <p>
                        E-Mail: kontakt@fronetic.de<br />
                        Telefon: +49 (0)9281 839 791 0<br />
                        Registergericht: Amtsgericht Hof<br />
                        Registernummer: HRB 5335
                    </p>
                </BlockUI>
                <BlockUI fullwidth={false}>
                    <strong>Verwendete Schriftarten</strong><br />
                    <div>
                        <div className={"bildnachweis"}>
                            <strong>Raleway by Matt McInerney, Pablo Impallari, Rodrigo Fuenzalida (Open Font License)</strong>
                            <div>
                                Überschriften, Fließtext
                            </div>
                        </div>
                    </div>
                    <br />

                    <strong>Bildnachweise</strong><br />
                    <div>
                    <div className={"bildnachweis"}>
                        <strong>AdobeStock by Jacob Lund</strong>
                        <div>
                            <a href={PrimaryRoutes.HOMEPAGE} target={"_self"}>Startseite</a>
                        </div>
                    </div>
                    <div className={"bildnachweis"}>
                        <strong>AdobeStock by Julien Eichinger</strong>
                        <div>
                            <a href={PrimaryRoutes.HOMEPAGE} target={"_self"}>Startseite</a>
                        </div>
                    </div>
                    </div>

                </BlockUI>
            </MainWrapper>
        );
    }
}

export default Imprint;