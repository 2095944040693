import * as React from "react";
import { connect } from "react-redux";
import store from "./store/store";
import { Route, Router } from "react-router-dom";
import { PrimaryRoutes } from "./routes/PrimaryRoutes";
import Footer from "./components/footer/Footer";
import "./assets/styles/style_general.scss";

import history from "./routes/BrowserHistory";

import Banner from "./components/banner/Banner";
import Startpage from "./components/startpage/Startpage";
import Imprint from "./components/pages/Imprint";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Conditions from "./components/pages/Conditions";


export class App extends React.Component<any, any> {
  render() {

    return (
      <>
        <Router history={history}>
          <Route path={PrimaryRoutes.HOMEPAGE}>
            <Banner />
          </Route>

          <Route path={PrimaryRoutes.HOMEPAGE} exact={true}>
            <Startpage />
          </Route>

          <Route path={PrimaryRoutes.IMPRINT} exact={true}>
            <Imprint />
          </Route>

          <Route path={PrimaryRoutes.PRIVACY_POLICIE} exact={true}>
            <PrivacyPolicy />
          </Route>

          <Route path={PrimaryRoutes.AGB} exact={true}>
            <Conditions />
          </Route>

          <Route path={PrimaryRoutes.HOMEPAGE}>
            <Footer />
          </Route>
        </Router>
      </>
    );
  }
}

const mapStateToProps = () => {
  const { token } = store.getState().SystemStateReducer;
  return {
    token: token,
  };
};

export default connect(mapStateToProps, null)(App);
