import * as React from "react";
import {NavLink} from "react-router-dom";
import Badge from "../badges/Badge";
import "./assets/style_cards.scss";

export enum CardStyle {
    DEFAULT = "card_default",
    PREMIUM = "card_premium",
    EXCLUSIVE = "card_exclusive",
    FLIPCARD = "card_flip_card",
    GALLERY = "card_gallery_card",
    CTA = "card_cta_card",
    INFO = "card_info",
    SEARCH_RESULT = "card_search_result"
}

export enum CardAccentColors {
    RED = "card_accent_red",
    GREEN = "card_accent_green",
    BLUE = "card_accent_blue",
    YELLOW = "card_accent_yellow",
    PINK = "card_accent_pink",
    ORANGE = "card_accent_orange"
}

export interface CardProps {
    /**
     * Die ID
     */
    id?: string,
    title: string,
    description?: string,
    price?: number,
    coverArt?: string,
    mediaID?: string,
    badges?: [],
    link?: string,
    subtitle?: string,
    pretitle?: string,
    cardStyle?: CardStyle,
    accentColor?: CardAccentColors,
    svgIcon?: string,
    rating?: number,
    basePrice?: number,
    favAble?: boolean,
    onClick?: Function,
    disabled?: boolean,
    placeholderIllustration?: boolean,
    features?: string[],
}

export enum WoroomSVGIcons {
    OFFICE = 'OFFICE',
    WORKSHOP = 'WORKSHOP',
    STORAGE = 'STORAGE',
    GENERAL = 'GENERAL',
}

interface CardWrapperProps {
    classes?: string,
    link?:string,
}

class CardWrapper extends React.Component<CardWrapperProps, any>{

    protected navlink(link?:string) {
        return link && <NavLink className={"overlay"} to={link} />;
    }



    render() {
        const {classes, children, link} = this.props;
        return (
            <div key={link} className={classes}>
                <div className={"card_core"}>
                    {children}
                    {this.navlink()}
                </div>
            </div>
        );
    }
}

export interface TitleProps {
    preTitle?:string,
    subTitle?:string
}

export class Title extends React.Component<TitleProps, any> {
    render() {
        const {preTitle, subTitle} = this.props;
        const preTitleElement = () => {
            return <div className={"pre_title"}>{preTitle}</div>;
        }
        const subTitleElement = () => {
            return <div className={"sub_title"}>{subTitle}</div>;
        }
        return (
            <div className={"title"}>
                {preTitle && preTitleElement()}
                <div className={"main_title"}>
                    {subTitle}
                </div>
                {subTitle && subTitleElement()}
            </div>
        );
    }
}

/**
 * Eine Card ist eines der primären Navigationselemente und Inhalts-Repräsnationsobjekte auf der gesamten Plattform.
 * Egal ob es sich um Bilder einer Gallerie-Komponente, Suchergebnisse oder ähnliches handelt
 */
class Card extends React.Component<CardProps> {

    state:CardProps = {
        id: "no_id_given",
        title: "No title",
        description: "No Description",
        price: 0.00,
        coverArt: "undefined",
        link: '/room',
    }


    protected navlink(linkT?:string, id?:string) {
        const {onClick, link} = this.props;

        if(onClick) {
            if(link) {
                return (<NavLink onClick={() => onClick()} className={"overlay"} to={link} />);
            }
            return <div className={"overlay"} onClick={() => {onClick()}} />
        } else {
            let linkTarget = "/room?__rid=" + id;
            if (linkT) {
                linkTarget = linkT;
            }
            return <NavLink className={"overlay"} to={linkTarget} />;
        }
    }

    protected newBadge(show?:boolean) {
        if (show) {
            return <div className={"new"}>
                <span>bester Preis</span>
            </div>;
        }
    }


    protected illustration(illustrationUrl?:string) {
        const {placeholderIllustration, mediaID} = this.props;
        if (illustrationUrl) {
            return <div className={"card_illustration"} style={{'backgroundImage': "url(" + illustrationUrl + ")"}} />;
        }
        if(placeholderIllustration) {
            return <div className={"card_illustration"} style={{'backgroundImage': "url(" + illustrationUrl + ")"}} />;
        }
    }

    protected titleHtml(title:string, pretitle?:string, subtitle?:string) {
        let titleContent = () => {
            return (
                <div className={"card_main_title"} dangerouslySetInnerHTML={{__html: title}} />
            );
        }
        return (
            <div className={"card_title"}>
                {pretitle && <div className={"card_pre_title"} dangerouslySetInnerHTML={{__html: pretitle}} />}
                {titleContent()}
                {subtitle && <div className={"card_sub_title"} dangerouslySetInnerHTML={{__html: subtitle}} />}
            </div>);
    }

    protected getAccentColorClass(accentColor?:CardAccentColors):string {
        let classes:string = '';
        switch (accentColor) {
            case CardAccentColors.RED:
                classes += ' ' + CardAccentColors.RED;
                break;
            case CardAccentColors.GREEN:
                classes += ' ' + CardAccentColors.GREEN;
                break;
            case CardAccentColors.YELLOW:
                classes += ' ' + CardAccentColors.YELLOW;
                break;
            case CardAccentColors.BLUE:
                classes += ' ' + CardAccentColors.BLUE;
                break;
            case CardAccentColors.PINK:
                classes += ' ' + CardAccentColors.PINK;
                break;
            case CardAccentColors.ORANGE:
                classes += ' ' + CardAccentColors.ORANGE;
                break;
            default:
        }
        return classes;
    }

    protected getClasses(cardStyle?:CardStyle, accentColor?:CardAccentColors) {
        const {disabled} = this.props;
        let classes = "card ";
        if (cardStyle) {
            classes += cardStyle;
        } else {
            classes += CardStyle.DEFAULT;
        }
        if(disabled) {
            classes += ' disabled';
        }
        classes += this.getAccentColorClass(accentColor)

        return classes;
    }

    render() {
        const {id, coverArt, link, title, description, subtitle, pretitle, cardStyle, accentColor, children, svgIcon, badges, favAble} = this.props;
        const style:CardStyle = cardStyle ? cardStyle : CardStyle.DEFAULT;

        let descriptionShort = description;

        const textContent = () => {
            return (
                <div className={"card_text"}>
                    {descriptionShort}
                </div>
            );
        }

        const miniContent = () => {
            return (
                <div className={"card_content"}>
                    {this.titleHtml(title, pretitle, subtitle)}
                    {children && children}
                </div>
            );
        }

        const defaultContent = () => {
            return (
                <div className={"card_content"}>
                    {this.titleHtml(title, pretitle, subtitle)}
                    {textContent()}
                    {children && children}
                </div>
            );
        }

        const badgesContent = () => {
            return (
                <>
                    <div className={"badge_left"}>
                        {badges?.map((badge, index) => {
                            return <Badge key={"card_badge_" + index} title={badge} />;
                        })}
                    </div>
                </>
            );
        }

        const classes = this.getClasses(cardStyle, accentColor);
        return (
            <CardWrapper classes={classes}>
                {svgIcon && <div className={"svgIcon"}><img src={svgIcon} alt={"icon"} /></div>}
                {defaultContent()}
                {badgesContent()}
            </CardWrapper>
        )
    }
}
export default Card;