import * as React from "react";
import {boundMethod} from "autobind-decorator";
import "./assets/style_headline.scss";

export enum HeadlineType {
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4",
    H5 = "H5",
    H6 = "H6"
}

export interface HeadlineProps {
    type: HeadlineType,
    preTitle?: string,
    subTitle?: string,
    title: string,
    interaction?:JSX.Element,
    className?: string,
    mobileToggler?: boolean
}

class Headline extends React.Component<any & HeadlineProps, any> {

    state = {
        toggled: false
    }

    @boundMethod
    async toggle() {
        const {toggled} = this.state;
        await this.setState({
            toggled: !toggled
        })
    }

    render() {
        const {type, interaction, title, subTitle, preTitle, className, mobileToggler} = this.props;
        const {toggled} = this.state;

        let classes = className ? "headline " + className : "headline";
        const onClick = () => {
            if(mobileToggler) {
                this.toggle();
            }
        }
        if (mobileToggler) {
            classes = toggled ? classes + " mobile_toggler open" : classes + " mobile_toggler closed";
        }

        const getSubTitle = () => {
            return <div className={"subtitle"} dangerouslySetInnerHTML={{__html: subTitle}} />;
        }
        const getPreTitle = () => {
            return <div className={"pretitle"} dangerouslySetInnerHTML={{__html: preTitle}} />;
        }
        const getHeadline = () => {
            switch (type) {
                case HeadlineType.H6:
                    return <h6 dangerouslySetInnerHTML={{__html: title}} />
                case HeadlineType.H5:
                    return <h5 dangerouslySetInnerHTML={{__html: title}} />
                case HeadlineType.H4:
                    return <h4 dangerouslySetInnerHTML={{__html: title}} />
                case HeadlineType.H3:
                    return <h3 dangerouslySetInnerHTML={{__html: title}} />
                case HeadlineType.H2:
                    return <h2 dangerouslySetInnerHTML={{__html: title}} />
                case HeadlineType.H1:
                    default:
                    return <h1 dangerouslySetInnerHTML={{__html: title}} />
            }
        }
        if(interaction) {
            return (
                <div className={classes + " interaction_header"}>
                    <div>
                        {preTitle && getPreTitle()}
                        {getHeadline()}
                        {subTitle && getSubTitle()}
                        <div className={"headline_block_spacer"} />
                    </div>
                    <div className={"flex_spacer"} />
                    <div className={"interactions"}>
                        {interaction}
                    </div>
                </div>
            );
        } else if(mobileToggler) {
           return(
               <div className={classes} onClick={onClick}>
                   <div>
                       {preTitle && getPreTitle()}
                       {getHeadline()}
                       {subTitle && getSubTitle()}
                       <div className={"headline_block_spacer"} />
                   </div>
                   <div className={"flex_spacer"} />
                   <div className={"headline_toggle_info"}>
                       {toggled ? "einklappen" : "ausklappen"}
                   </div>
               </div>
           );
        } else {
            return (
                <div className={classes}>
                    {preTitle && getPreTitle()}
                    {getHeadline()}
                    {subTitle && getSubTitle()}
                    <div className={"headline_block_spacer"} />
                </div>
            );
        }
    }
}

export default Headline;