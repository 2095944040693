export enum Locale {
  DE = "DE",
  UA = "UA",
  EN = "EN",
}

export const initialSystemState: SystemState = {
  userMenuActive: false,
  dialogOpen: false,
  locale: Locale.DE,
};

interface SystemState {
  userMenuActive?: boolean;

  dialogOpen?: boolean;

  token?: string;
  tokenReceivedAt?: string;
  tokenExpiresIn?: string;
  refreshToken?: string;
  locale?: Locale;
}

export default SystemState;
