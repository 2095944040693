export enum PrimaryRoutes {
  HOMEPAGE = "/",

  ABOUT = "/about",
  PRESS = "/press",

  IMPRINT = "/impressum",
  PRIVACY_POLICIE = "/datenschutzerklaerung",
  AGB = "/agb",
  CONTACT = "/contact",
  FAQ = "/faq",
  HELP = "/help",

  HELP_HOW = "/so-koennen-sie-helfen",
  HELP_WHERE = "/hier-wird-hilfe-gesucht",
  HELP_CONTACT_PERSON = "/ansprechpartner",

  TESTER = "/tester",
}
