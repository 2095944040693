import * as React from "react";
import {CSSProperties} from "react";
import Globals from "../../config/globals";
import "./assets/style_block.scss";

export enum BlockStyle {
    DEFAULT = "default",
    HIGHLIGHT = "highlight",
    HIGHLIGHT_2 = "highlight_2",
    EMPHASIZED = "emphasized"
}

export interface BlockProps {
    fullwidth:boolean,
    backgroundImage?:string,
    additionalClass?:string,
    style?:BlockStyle,
    patternOverlay?: boolean;
    contentBlock?: boolean,
}

/**
 * Block are meant to contain all other components. They are used for the main paddings and content centering.
 */
class BlockUI extends React.Component <BlockProps> {

    render() {
        const {fullwidth, patternOverlay, backgroundImage, additionalClass, style, contentBlock, children} = this.props;

        let background:CSSProperties = {};
        if (backgroundImage) {
            background = {'backgroundImage':  'url(' + Globals.SYSTEM_FULL_URL + backgroundImage + ')'};
        }

        let classes = "block";
        classes = style ? classes + " " + style : classes;
        classes = additionalClass ? classes + " " + additionalClass : classes;
        classes = fullwidth ? classes + " fullwidth" : classes;
        classes = contentBlock ? classes + " content_block" : classes;

        return (
            <div className={classes} style={background}>
                {patternOverlay && <div className={"sw-pattern-overlay"} />}
                <div className={"block_core"}>
                    {children}
                </div>
            </div>
        );
    }
}

export default BlockUI;