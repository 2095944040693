import * as React from "react";
import BlockUI from "../blocks/BlockUI";
import "./assets/style_banner.scss";
import store from "../../store/store";
import { connect } from "react-redux";
import { PrimaryRoutes } from "../../routes/PrimaryRoutes";
import logo from "./assets/spaces_dev_logo.svg";
import { NavLink } from "react-router-dom";
import IconAnimated, { IconsAnimated } from "../icons/IconAnimated";
import { boundMethod } from "autobind-decorator";
import { Locale } from "../../services/system/SystemState";

export interface BannerState {
  menuActive: boolean;
}

class Banner extends React.Component<any, BannerState> {
  state = {
    menuActive: false,
  };

  @boundMethod
  async toggleMainMenuMobile() {
    const { menuActive } = this.state;
    await this.setState({
      menuActive: !menuActive,
    });
  }

  render() {
    const { menuActive } = this.state;
    const { isAdmin } = this.props;

    const getMenuItems = (mobile?: boolean) => {
      return (
        <>
            <a
                href={"https://www.fronetic.com"}
                onClick={mobile ? () => this.toggleMainMenuMobile() : () => {}}
                rel={"noopener"}

            >
              by FRONETIC. GmbH
            </a>
        </>
      );
    };

    const getMenu = () => {
      return (
        <>
          <div className={"main_menu"}>{getMenuItems()}</div>
          <div className={"mobile_menu"}>
            <IconAnimated
              active={menuActive}
              onClick={(e: any) => this.toggleMainMenuMobile()}
              iconType={IconsAnimated.MENU_BURGER}
            />
          </div>
        </>
      );
    };

    const getMobileMenuContainer = () => {
      const classes = menuActive
        ? "mobile_menu_container active"
        : "mobile_menu_container";

      return (
        <nav className={classes}>
          <a
            href={""}
            className={"close_menu"}
            onClick={(e) => {
              e.preventDefault();
              this.toggleMainMenuMobile();
            }}
          >
            Menu schließen
          </a>
          {getMenuItems(true)}
        </nav>
      );
    };

    return (
      <>
        <header className={"banner"}>
          <BlockUI fullwidth={false} additionalClass={"language_header"}>
          </BlockUI>
          <BlockUI fullwidth={false}>
            <div className={"logo"}>
              <NavLink to={PrimaryRoutes.HOMEPAGE} target={"_self"}>
                <img alt={"border image"} src={logo} />
              </NavLink>
            </div>
            <div className={"flex_spacer"} />
            {getMenu()}
          </BlockUI>
        </header>
        {getMobileMenuContainer()}
      </>
    );
  }

  @boundMethod
  async setLanguage(locale: Locale) {
    const { setLanguage } = this.props;
    if (setLanguage) {
      setLanguage(locale);
    }
  }
}

const mapStateToProps = () => {
  const { token, locale } = store.getState().SystemStateReducer;
  return {
    token: token,
    locale: locale,
  };
};


export default connect(mapStateToProps, null)(Banner);
