import * as React from "react";
import "./assets/style_hero.scss";
import Background from './assets/hero-image-co-working.jpg';

export interface HeroUIProps {
    mediaId?: string,
    contentHero?: boolean,
    additionalClass?: string,
    background?: string
}

class HeroUI extends React.Component<HeroUIProps, any> {
    render() {
        const {mediaId, additionalClass, children, background} = this.props;
        const classes = additionalClass ? "hero " + additionalClass : "hero";
        let sectionStyle = {
            backgroundImage: "url(" + Background + ")"
        };
        return (
            <div className={classes} style={mediaId ? {} : sectionStyle}>
                <div className={"hero_core"}>
                    {children}
                </div>
            </div>
        )
    }
}

export default HeroUI;