import * as React from "react";
import "./assets/style_infosections.scss";
import BlockUI from "../blocks/BlockUI";
import { NavLink } from "react-router-dom";

export interface InfoSectionProps {
  reverse?: boolean;
  title: string;
  text: any;
  background?: string;
  link?: string;
}

class InfoSection extends React.Component<InfoSectionProps, any> {
  render() {
    const { reverse, title, text, background, link } = this.props;
    const classes = reverse ? "info_section reverse" : "info_section";
    return (
      <BlockUI fullwidth={false} contentBlock={true}>
        <div className={classes}>
          {reverse && <div className={"flex_spacer"} />}
          <div className={"info_section_content"}>
            <div className={"info_section_title"}>{title}</div>
            <div className={"info_section_text"}>
              {text}
            </div>
          </div>
          <div className={"info_section_illustration"}>
            {background && (
              <img src={background} alt={title + " Illustration"} />
            )}
          </div>
          {link && (
            <NavLink
              className={"info_section_link"}
              to={link}
              target={"_self"}
            />
          )}
        </div>
      </BlockUI>
    );
  }
}

export default InfoSection;
