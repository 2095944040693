import * as React from "react";
import BlockUI from "../blocks/BlockUI";
import "./assets/footer.scss";
import { PrimaryRoutes } from "../../routes/PrimaryRoutes";
import store from "../../store/store";
import { connect } from "react-redux";
import logo from "./assets/spaces_dev_logo.svg";
import { Locale } from "../../services/system/SystemState";
import { NavLink } from "react-router-dom";

class Footer extends React.Component<any, any> {
  render() {
    const { locale } = this.props;

    const getTextFAQ = () => {
      switch (locale) {
        case Locale.UA:
          return "Часті запитання (FAQ)";
        case Locale.EN:
          return "Frequently Asked Questions (FAQ)";
        case Locale.DE:
        default:
          return "Häufig gestellte Fragen (FAQ)";
      }
    };

    const getTextWhere = () => {
      switch (locale) {
        case Locale.UA:
          return "Де шукати допомоги?";
        case Locale.EN:
          return "Where help is sought";
        case Locale.DE:
        default:
          return "Wo wird Hilfe gesucht";
      }
    };

    const getTextContact = () => {
      switch (locale) {
        case Locale.UA:
          return "Які там контакти?";
        case Locale.EN:
          return "What are the contact persons";
        case Locale.DE:
        default:
          return "Welche Ansprechpartner gibt es";
      }
    };

    const getTextImprint = () => {
      switch (locale) {
        case Locale.UA:
          return "відбиток";
        case Locale.EN:
          return "Imrint";
        case Locale.DE:
        default:
          return "Impressum";
      }
    };

    const getTextDSGVO = () => {
      switch (locale) {
        case Locale.UA:
          return "Захист даних";
        case Locale.EN:
          return "Privacy policy";
        case Locale.DE:
        default:
          return "Datenschutzerklärung";
      }
    };

    const getTextAbout = () => {
      switch (locale) {
        case Locale.UA:
          return "Про WOROOM Refuge";
        case Locale.EN:
          return "About WOROOM Refuge";
        case Locale.DE:
        default:
          return "Über WOROOM Refuge";
      }
    };

    const getHelpText = () => {
      switch (locale) {
        case Locale.UA:
          return "допомогти";
        case Locale.EN:
          return "Help";
        case Locale.DE:
        default:
          return "Hilfe";
      }
    };

    const getTextAGB = () => {
      switch (locale) {
        case Locale.UA:
          return "Умови";
        case Locale.EN:
          return "Terms & Conditions";
        case Locale.DE:
        default:
          return "AGBs";
      }
    };

    const getTextSupport = () => {
      switch (locale) {
        case Locale.UA:
          return "Допомога та підтримка";
        case Locale.EN:
          return "Help & Support";
        case Locale.DE:
        default:
          return "Hilfe & Support";
      }
    };

    const getLinks = () => {
      return (
        <>
          <nav>
            <NavLink target={"_self"} to={PrimaryRoutes.FAQ}>
              {getTextFAQ()}
            </NavLink>
            <NavLink target={"_self"} to={PrimaryRoutes.HELP}>
              {getHelpText()}
            </NavLink>
          </nav>
        </>
      );
    };

    return (
      <footer>
        <BlockUI fullwidth={false}>
          <div className={"footer_box"}></div>
          <div className={"flex_spacer"} />
          <div className={"footer_box logo_box"}>
            <div className={"flex_spacer"} />
            <div className={"footer_logo"}>
              <img alt={"border"} src={logo} />
            </div>
          </div>
        </BlockUI>
        <BlockUI fullwidth={false} additionalClass={"legal_links"}>
          <nav>
            <div className={"flex_spacer"} />
            <NavLink target={"_self"} to={PrimaryRoutes.IMPRINT}>
              {getTextImprint()}
            </NavLink>
            <NavLink target={"_self"} to={PrimaryRoutes.PRIVACY_POLICIE}>
              {getTextDSGVO()}
            </NavLink>
            <NavLink target={"_self"} to={PrimaryRoutes.AGB}>
              {getTextAGB()}
            </NavLink>
            <div className={"flex_spacer"} />
          </nav>
        </BlockUI>
      </footer>
    );
  }
}

const mapStateToProps = () => {
  const { token, locale } = store.getState().SystemStateReducer;
  return {
    token: token,
    locale: locale,
  };
};

export default connect(mapStateToProps, null)(Footer);
