import * as React from "react";
import "./assets/style_badge.scss";

export enum BadgeStyle {
  RED = "red",
  BLUE = "blue",
  DEFAULT = "default",
}

export interface BadgeProps {
  title: string;
  className?: string;
  style?: BadgeStyle;
}

export interface BadgeState {}

class Badge extends React.Component<BadgeProps, BadgeState> {
  render() {
    const { title, className, style } = this.props;
    let classes = "badge";
    if (className) {
      classes += " " + className;
    }
    if (style) {
      classes += "badge_" + style;
    }
    return <div className={classes}>{title}</div>;
  }
}

export default Badge;
