import * as React from "react";
import {boundMethod} from "autobind-decorator";
import "./assets/style_step_box.scss"

export interface StepBoxStepState {
    active?: boolean
}

export interface StepBoxStepProps {
    number: number;
    title: string;
    content: string;
    imageURL?: string
}

export class StepBoxStep extends React.Component<StepBoxStepProps, StepBoxStepState> {
    state = {
        active: false
    }

    @boundMethod
    async toggleActive () {
        const {active} = this.state;
        await this.setState({
            active: !active
        })
    }

    render() {
        const {active} = this.state;
        const { number, title, content, imageURL} = this.props;
        const classes = active? "step active" : "step";
        return (
            <div className={classes} onClick={this.toggleActive}>
                <div className={"step_number"}>
                    <div className={"step_number_wrapper"}><span>{number}</span></div>
                </div>
                <div className={"step_content"}>
                    <div className={"title"}>{title}</div>
                    <div className={"text"}>{content}</div>
                </div>
            </div>
        );
    }
}



class StepBox extends React.Component<any, any> {
    render() {
        const {children} = this.props;
        return (
            <div className={"step_box"}>
                {children}
            </div>
        );
    }
}

export default StepBox;